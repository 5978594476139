import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
// import * as Sentry from "@sentry/react";
import App from "./App.tsx";
import "./index.css";

// Sentry.init({
//   dsn: "https://f9b297cde00bbe97593dc54600175ba4@o4506530316419072.ingest.sentry.io/4506530323234816",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         "localhost",
//         /^https:\/\/instrumentar\.com\.br\/api/,
//       ],
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
