import React from 'react';
import LogoImg from '../assets/bordered-nurse.svg';
import LogoDedImg from "../assets/error-logo.svg";
import LogoHappyImg from "../assets/happy-logo.svg";

export const Logo = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > & { size?: number }
) => (
  <img
    src={LogoImg}
    style={{
      maxHeight: "100%",
      verticalAlign: "middle",
      marginBottom: 4,
      height: props.size,
      ...props.style,
    }}
    {...props}
  />
);

export const LogoDed = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > & { size?: number }
) => (
  <img
    src={LogoDedImg}
    style={{ maxHeight: "100%", height: props.size, ...props.style }}
    {...props}
  />
);

export const LogoHappy = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > & { size?: number }
) => (
  <img
    src={LogoHappyImg}
    style={{ maxHeight: "100%", height: props.size, ...props.style }}
    {...props}
  />
);
