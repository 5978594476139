import { Box, Group, Paper, Text, Title, Alert, Flex } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { Logo, LogoHappy } from "../../../components/Logo";
import { LinkButton } from "../../../components/LinkButton";

export const PaymentOnboarding = () => {
  return (
    <Flex align="center" justify="center">
      <Paper maw={700} radius="md" p="xl" withBorder m="md">
        <Box mx="auto">
          <Title
            size={"h2"}
            c="var(--mantine-color-gray-7)"
            ta="center"
            pb={"sm"}
          >
            <Logo height={40} /> Instrumentar
          </Title>
          <Title
            pt={"xl"}
            mb={"xl"}
            order={1}
            ta={"center"}
            style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
          >
            Você foi convidado!
          </Title>
          <Group justify="center" mb={"xl"}>
            <LogoHappy style={{ maxWidth: "100%" }} />
          </Group>
          <Title mb={"lg"} order={3}>
            Você foi convidado ao pré-lançamento do Instrumentar!
          </Title>
          <Text mb={"md"} size="lg">
            Com este convite você terá acesso antecipado e será um dos primeiros
            a experimentar o futuro da instrumentação!
          </Text>
          <Text mb={"md"} size="lg">
            Como o aplicativo ainda está em pré-lançamento, algumas
            funcionalidades ainda não estão disponíveis. Mas estamos trabalhando
            duro e você vai ver várias novidades aparecendo!
          </Text>
          <Text mb={"md"} size="lg" fw="bold">
            Durante este periodo, o acesso à plataforma e suas funcionalidades é
            completamente gratuito. Aproveite!
          </Text>
          <Alert
            variant="light"
            color="blue"
            radius="md"
            title="Acesso antecipado"
            icon={<IconInfoCircle />}
          >
            <Text mb={"md"}>
              Como a plataforma ainda está em pré-lançamento, pode ser que de
              vez em quando você encontre um erro ou outro. Ainda estamos
              aprimorando o produto e corrigindo erros.
            </Text>
          </Alert>
          <Group justify="right" mt="md">
            <LinkButton fullWidth to="/register">
              Se inscrever!
            </LinkButton>
          </Group>
        </Box>
      </Paper>
    </Flex>
  );
};
