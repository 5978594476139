import classes from "./index.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Stack,
  Alert,
  Flex,
  LoadingOverlay,
  Title,
} from "@mantine/core";

import { Container } from "../../components/Container";
import { useSupabaseClient, useSession } from "@supabase/auth-helpers-react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircle } from "@tabler/icons-react";

export function ForgotPassword(props: PaperProps) {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [updatePass, setUpdatePass] = useState<boolean>(false);

  const navigate = useNavigate();
  const supabase = useSupabaseClient();
  const session = useSession();

  useEffect(() => {
    if (session) {
      navigate("/app");
    }
  }, [session, navigate]);

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event) => {
      if (event == "PASSWORD_RECOVERY") {
        setUpdatePass(true);
      }
    });
  }, []);

  const form = useForm({
    initialValues: {
      email: "",
      confirm: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Email inválido"),
      password: (val) =>
        val.length <= 6
          ? "Senha deve conter ao menos 6 letras ou dígitos"
          : null,
      confirm: (val, { password }) => {
        return val === password
          ? null
          : "Confirmação de senha e senha não correspondem";
      },
    },
  });

  const handleFormSubmit = async () => {
    setLoading(true);
    if (!updatePass) {
      const { error } = await supabase.auth.resetPasswordForEmail(
        form.values.email
      );
      if (error) {
        setError(String(error.status));
      }
    } else {
      const { error } = await supabase.auth.updateUser({
        password: form.values.password,
      });
      notifications.show({
        id: "updating-password",
        loading: true,
        title: "Atualizando sua senha",
        message: "Isso deve levar só alguns segundos",
        autoClose: false,
        withCloseButton: false,
      });

      if (error) {
        notifications.update({
          id: "updating-password",
          message: "Erro atualizando sua senha",
          icon: <IconExclamationCircle size="1.2rem" />,
          autoClose: 2000,
          withCloseButton: true,
          loading: false,
          color: "red",
        });
      } else {
        notifications.update({
          id: "updating-password",
          loading: false,
          message: "Senha atualizada com sucesso!",
          color: "teal",
          autoClose: 2000,
          withCloseButton: true,
          icon: <IconCheck size="1rem" />,
        });
        setTimeout(() => navigate("/app"), 500);
      }
    }
    setLoading(false);
  };

  return (
    <Container>
      <Flex
        align="center"
        justify="center"
        h="100%"
        direction="column"
        gap={"xl"}
      >
        <Title ta="center" className={classes.title} fw={900}>
          Esqueceu sua senha?
        </Title>
        <Text c="dimmed" size="sm" ta="center" mt={5}>
          Acontece com todo mundo! Vamos criar uma nova?
        </Text>
        <Paper
          radius="md"
          p="xl"
          className={classes.paper}
          withBorder
          {...props}
        >
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />

          <form onSubmit={form.onSubmit(handleFormSubmit)}>
            <Stack>
              <Text size="xl">Reiniciar senha</Text>
              {updatePass ? (
                <>
                  <PasswordInput
                    required
                    label="Senha"
                    placeholder="Sua senha"
                    type="password"
                    name="password"
                    value={form.values.password}
                    onChange={(event) =>
                      form.setFieldValue("password", event.currentTarget.value)
                    }
                    error={
                      form.errors.password &&
                      "Senha deve conter ao menos 6 letras ou dígitos"
                    }
                    radius="md"
                  />

                  <PasswordInput
                    required
                    label="Confirme sua senha"
                    placeholder="Sua senha"
                    value={form.values.confirm}
                    onChange={(event) =>
                      form.setFieldValue("confirm", event.currentTarget.value)
                    }
                    error={
                      form.errors.confirm &&
                      "Confirmação de senha e senha não correspondem"
                    }
                    radius="md"
                  />
                </>
              ) : (
                <TextInput
                  required
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="meuemail@exemplo.com"
                  value={form.values.email}
                  onChange={(event) =>
                    form.setFieldValue("email", event.currentTarget.value)
                  }
                  error={form.errors.email && "Email inválido"}
                  radius="md"
                />
              )}

              {error && <Alert color="red">{error}</Alert>}
            </Stack>

            <Group justify="space-between" mt="sm">
              <Button fullWidth type="submit">
                Reiniciar senha
              </Button>
            </Group>
          </form>
        </Paper>
      </Flex>
    </Container>
  );
}
