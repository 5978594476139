import {
  createBrowserRouter,
  redirect,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { useRegisterSW } from "virtual:pwa-register/react";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

import { LoggedOut } from "./pages/loggedOut";
import { Login } from "./pages/login";
import {
  getSurgery,
  supabase,
  getProfile,
  getUser,
} from "./lib/supabaseClient";
import { VerifyEmail } from "./pages/verifyEmail";
import { Home } from "./pages/app/home";
import { EditSurgery } from "./pages/app/editSurgery";
import { Container } from "./components/Container";
// import { ViewReceipt } from "./pages/app/viewReceipt";
import { Profile } from "./pages/app/profile";
import { Onboarding } from "./pages/app/onboarding";
import { PaymentOnboarding } from "./pages/app/onboarding/payment";
//import { ValidateReceipt } from "./pages/validateReceipt";
import { LogoDed } from "./components/Logo";
import { Register } from "./pages/login/register";
import { LinkButton } from "./components/LinkButton";
import { ForgotPassword } from "./pages/login/forgot-password";

const router = createBrowserRouter([
  {
    path: "/",
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        return redirect("/app");
      }
      return null;
    },
    element: <LoggedOut />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/login",
    element: <Login />,
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        return redirect("/app");
      }
      return null;
    },
  },
  {
    path: "/register",
    element: <Register />,
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        return redirect("/app");
      }
      return null;
    },
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        return redirect("/app");
      }
      return null;
    },
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
  {
    path: "/app/onboarding",
    element: <Onboarding />,
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) {
        return redirect("/login");
      }
      const profile = await getProfile();
      return profile || null;
    },
  },
  {
    path: "/r/:id",
    errorElement: <ErrorBoundary />,
    lazy: () => import("./pages/validateReceipt"),
  },
  {
    path: "/error",
    element: <ErrorBoundary />,
  },
  {
    path: "/jornada-carioca",
    element: <PaymentOnboarding />,
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        return redirect("/app");
      }
      return null;
    },
  },
  {
    path: "/app",
    errorElement: <ErrorBoundary />,
    loader: async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) {
        return redirect("/login");
      }
      if (!(await getProfile())) {
        return redirect("/app/onboarding");
      }

      return session;
    },
    element: <Container />,
    children: [
      {
        path: "add-surgery",
        element: <EditSurgery />,
        loader: () => ({ editing: false, surgery: {} }),
      },
      {
        path: "edit-surgery/:id",
        element: <EditSurgery />,
        loader: async ({ params }) => {
          if (!params.id) {
            return redirect("/app");
          }
          const surgery = await getSurgery(params.id);

          return {
            editing: true,
            surgery,
          };
        },
      },
      {
        path: "receipt/:id",
        // element: <ViewReceipt />,
        lazy: () => import("./pages/app/viewReceipt"),
        loader: async ({ params }) => {
          if (!params.id) {
            return redirect("/app");
          }
          const surgery = await getSurgery(params.id);
          const profile = await getProfile();
          if (surgery && profile) {
            return {
              surgery,
              profile,
            };
          }
          throw new Error("Cirurgia não encontrada");
          return null;
        },
      },
      {
        path: "profile",
        element: <Profile />,
        loader: async () => {
          const profile = await getProfile();
          return profile || null;
        },
      },
      {
        index: true,
        path: ":page?",
        element: <Home />,
        shouldRevalidate: () => false,
        loader: async () => {
          const user = await getUser();
          if (!user) {
            return redirect("/login");
          }
          return null;
        },
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]);

function ErrorBoundary() {
  const error = useRouteError() as Error;
  console.error(error);
  return (
    <div
      style={{
        margin: "auto",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: 50,
      }}
    >
      <LogoDed style={{ maxWidth: 400 }} />
      <h1>Ih gente, deu erro!</h1>
      <pre>
        {String(error?.message || "")}. <br />
        Tente mais uma vez ou entre em contato.
      </pre>
      <LinkButton to="/app">Voltar para a página principal</LinkButton>
    </div>
  );
}

const theme = {
  fontFamily: "Roboto, Inter, Avenir, Helvetica, system-ui, Arial, sans-serif",
  fontFamilyMonospace: "Monaco, Courier, monospace",
};

function App() {
  useRegisterSW({
    immediate: true,
    onRegisteredSW(swUrl, r) {
      const intervalMS = 60 * 60 * 1000;
      return (
        r &&
        setInterval(async () => {
          if (r.installing || !navigator) return;

          if ("connection" in navigator && !navigator.onLine) return;

          const resp = await fetch(swUrl, {
            cache: "no-store",
            headers: {
              cache: "no-store",
              "cache-control": "no-cache",
            },
          });

          if (resp?.status === 200) await r.update();
        }, intervalMS)
      );
    },
  });
  return (
    <SessionContextProvider supabaseClient={supabase}>
      <MantineProvider theme={theme}>
        <Notifications />
        <RouterProvider router={router} />
      </MantineProvider>
    </SessionContextProvider>
  );
}

export default App;
