import classes from "./Container.module.css";
import { ReactNode } from "react";
import {
  AppShell,
  Avatar,
  Group,
  LoadingOverlay,
  Menu,
  rem,
} from "@mantine/core";
import { Logo } from "./Logo";
import { Link, Outlet, useNavigation } from "react-router-dom";
import { LinkMenuItem } from "./LinkButton";
import { IconLogout, IconSettings, IconUserCircle } from "@tabler/icons-react";
import { useSupabaseClient, useSession } from "@supabase/auth-helpers-react";
import { LandingFooter } from "./LandingFooter";

export const Container = ({ children }: { children?: ReactNode }) => {
  const navigation = useNavigation();
  const supabase = useSupabaseClient();
  const session = useSession();

  const clickLogout = () => {
    window.localStorage.clear();
    supabase.auth.signOut().finally(() => (document.location.href = "/"));
  };

  return (
    <AppShell pos="relative" padding="md" header={{ height: 60 }}>
      <AppShell.Header>
        <header
          style={{
            padding: rem(2),
            fontSize: rem(22),
            fontWeight: 600,
          }}
        >
          <Group justify="space-between" px="md">
            <Link
              style={{
                color: "inherit",
                textDecoration: "inherit",
                lineHeight: rem(56),
              }}
              to={session ? "/app" : "/"}
            >
              <Logo height={42} /> Instrumentar
            </Link>

            {session && (
              <Menu position="bottom-end" withArrow>
                <Menu.Target>
                  <Avatar radius="xl" style={{ cursor: "pointer" }}>
                    <IconSettings size="1.5rem" />
                  </Avatar>
                </Menu.Target>

                <Menu.Dropdown>
                  <LinkMenuItem
                    to="/app/profile"
                    leftSection={
                      <IconUserCircle
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Perfil
                  </LinkMenuItem>
                  <Menu.Divider />
                  <Menu.Item
                    onClick={clickLogout}
                    leftSection={
                      <IconLogout style={{ width: rem(14), height: rem(14) }} />
                    }
                  >
                    Desconectar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>
        </header>
      </AppShell.Header>
      <AppShell.Main className={classes.main}>
        <LoadingOverlay
          visible={navigation.state === "loading"}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        {children || <Outlet />}
      </AppShell.Main>
      <LandingFooter />
    </AppShell>
  );
};
