import {
  Group,
  Paper,
  Stack,
  Pagination,
  Text,
  Skeleton,
  LoadingOverlay,
} from "@mantine/core";
import { ISurgery } from "../../../types";
import { Table } from "./table";
// import { useState } from "react";
import { LinkButton } from "../../../components/LinkButton";
import { useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { CardList } from "./cardList";
import { IconPlus } from "@tabler/icons-react";
import { useState, useEffect } from "react";
import { getSurgeriesPage } from "../../../lib/supabaseClient";

// const FilterMenu = () => {
//   const [opened, { open, close }] = useDisclosure(false);
//   return (
//     <>
//       <Drawer opened={opened} onClose={close} title="Authentication">
//         {/* Drawer content */}
//       </Drawer>

//       <Group justify="center">
//         <Button onClick={open}>Open Drawer</Button>
//       </Group>
//     </>
//   );
// return (
//   <Menu shadow="md" width={200}>
//     <Menu.Target>
//       <Button variant="filled" color="blue" size="sm">
//         <IconFilter /> Filtrar
//       </Button>
//     </Menu.Target>

//     <Menu.Dropdown>
//       <Menu.Label>Status</Menu.Label>
//       <Menu.Item>Pendente</Menu.Item>
//       <Menu.Item>Marcada</Menu.Item>
//       <Menu.Item>Paga</Menu.Item>
//       <Menu.Item>Cancelada</Menu.Item>
//       <Menu.Divider />

//       <Menu.Label>Danger zone</Menu.Label>
//       <Menu.Item>Transfer my data</Menu.Item>
//       <Menu.Item color="red">Delete my account</Menu.Item>
//     </Menu.Dropdown>
//   </Menu>
// );
// };
const PAGE_SIZE = 20;
export const Home = () => {
  const [pagesTotal, setPagesTotal] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [surgeries, setSurgeries] = useState<ISurgery[]>([]);
  const page = Number(useParams().page) || 1;

  const navigate = useNavigate();
  const bigScreen = useMediaQuery("(min-width: 880px)");

  useEffect(() => {
    setLoading(true);
    (async () => {
      const data = await getSurgeriesPage(page, PAGE_SIZE);
      if (!data) {
        return;
      }
      setSurgeries(data.surgeries);
      setPagesTotal(data.count ? Math.ceil(data.count / PAGE_SIZE) : 0);
      setLoading(false);
      // }
    })();
  }, [page, getSurgeriesPage, PAGE_SIZE]);

  const renderTable = bigScreen ? (
    <Table data={surgeries} />
  ) : (
    <CardList data={surgeries} />
  );

  const Comp = ({ children }: { children: React.ReactNode }) =>
    bigScreen ? (
      <Paper radius="md" p="xl" withBorder pos="relative">
        {children}
      </Paper>
    ) : (
      <>{children}</>
    );

  return (
    <Stack>
      <Group justify="end">
        <LinkButton to="/app/add-surgery" leftSection={<IconPlus size={14} />}>
          Adicionar cirurgia
        </LinkButton>
        {/* <Text>Esse mês: R${total}</Text> */}
        {/* <Button variant="filled" color="blue" size="sm">
            <IconSortAscending /> Ordem
          </Button> 
          <FilterMenu /> */}
      </Group>
      <Comp>
        {pagesTotal === 0 ? (
          <>
            <Text>Você ainda não criou nenhuma cirurgia.</Text>
            <LinkButton to="/app/add-surgery">Adicionar cirurgia</LinkButton>
          </>
        ) : (
          <Stack>
            {loading && !surgeries.length ? (
              Array(PAGE_SIZE)
                .fill(0)
                .map((_, i) => {
                  return <Skeleton radius="xl" height={48} p={24} key={i} />;
                })
            ) : (
              <>
                <LoadingOverlay
                  visible={loading}
                  zIndex={90}
                  overlayProps={{ radius: "sm", blur: 2 }}
                />
                {renderTable}
              </>
            )}
            {pagesTotal && (
              <Group justify="right">
                <Pagination
                  total={pagesTotal}
                  value={page}
                  onChange={(p) => navigate(`/app/${p}`)} //setPage(p)}
                  withControls={false}
                  siblings={2}
                  boundaries={1}
                />
              </Group>
            )}
          </Stack>
        )}
      </Comp>
    </Stack>
  );
};
