import classes from "./index.module.css";
import {
  Title,
  Text,
  Container,
  Stack,
  Group,
  Paper,
  Flex,
  ThemeIcon,
  Grid,
  Box,
  // TextInput,
  // Button,
} from "@mantine/core";
// import { Turnstile } from "@marsidev/react-turnstile";
import { Logo } from "../../components/Logo";
import { LinkButton } from "../../components/LinkButton";
import nursePic from "../../assets/transparente.png";
import mockupImg from "../../assets/mockup.png";
import { LandingFooter } from "../../components/LandingFooter";
import {
  IconAlarm,
  IconCoins,
  IconListDetails,
  IconReceipt,
} from "@tabler/icons-react";
// import { useState } from "react";

const ListPaper = ({ children }: React.PropsWithChildren) => (
  <Grid.Col span={{ md: 6, lg: 5 }}>
    <Paper shadow="xs" p="md" h="100%">
      <Flex
        gap="md"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="nowrap"
      >
        {children}
      </Flex>
    </Paper>
  </Grid.Col>
);

// const WaitList = () => {
//   const [captchaToken, setCaptchaToken] = useState<string | undefined>();
//   const [email, setEmail] = useState<string>();

//   const subscribe = () => {
//     //captchaToken;
//   };

//   return (
//     <Stack p={"xl"}>
//       <Title size={"h2"}>Lista de espera</Title>
//       <Text>
//         As inscrições no Instrumentar ainda não estão abertas. Inscreva-se
//       </Text>
//       <TextInput
//         size="lg"
//         placeholder="Seu email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//       />
//       <Turnstile
//         siteKey="0x4AAAAAAAM4NFMwdqH6Jvdr"
//         onSuccess={(token) => setCaptchaToken(token)}
//         options={{ theme: "light" }}
//       />
//       <Button
//         size="md"
//         onClick={() => {
//           subscribe();
//         }}
//       >
//         Se inscrever na lista de espera
//       </Button>
//     </Stack>
//   );
// };

export const LoggedOut = () => {
  return (
    <Box p={0}>
      <div className={classes.hero}>
        <Container className={classes.unskew}>
          <Flex className={classes.topNav}>
            <Logo className={classes.logo} />
            <h1 className={classes.title}>Instrumentar</h1>
          </Flex>
          <LinkButton to="/login" size="lg" className={classes.loginButton}>
            Entrar
          </LinkButton>
          <Flex gap="md" justify="center" align="start" direction="row">
            <p className={classes.heroText}>
              A plataforma do Instrumentador Cirúrgico.
            </p>
            <img
              className={classes.imagemTelefone}
              style={{ marginBottom: -30 }}
              src={mockupImg}
              alt="telefone com exemplo do aplicativo"
            />
          </Flex>
        </Container>
      </div>
      <Container mb={60}>
        <Grid gutter={"xl"} align="center">
          <Grid.Col span={{ md: 6, lg: 6 }}>
            <div className={classes.nursePicBox}>
              <img
                src={nursePic}
                alt="Pra que serve?"
                className={classes.nursePic}
              />
              <div className={classes.nursePicBack}></div>
              <Box className={classes.nursePicTextBox} mt={"sm"} mx={"xl"}>
                <p className={classes.nursePicText}>Como me ajuda?</p>
              </Box>
            </div>
          </Grid.Col>
          <Grid.Col span={{ md: 6, lg: 6 }}>
            <Stack>
              <Title order={2} size="xl" className={classes.description}>
                O seu trabalho é instrumentar ou é cuidar de papelada?
              </Title>
              <Text size="lg" className={classes.description}>
                Você é um profissional da saúde cansado de lidar com papelada,
                rastreando cirurgias e gerenciando suas finanças? Diga adeus ao
                incômodo e dê as boas-vindas ao Instrumentar - a solução
                completa projetada para simplificar sua vida profissional.
              </Text>
            </Stack>
          </Grid.Col>
        </Grid>
        <Grid grow gutter={"lg"} align="stretch" justify="stretch">
          <ListPaper>
            <Stack>
              <Group>
                <ThemeIcon variant="light" radius="xl" size="xl">
                  <IconListDetails size={"1.8em"} />
                </ThemeIcon>
                <Title order={3}>Acompanhe todas suas cirurgias</Title>
              </Group>
              Diga olá ao trabalho mais organizado e sem estresse. Registre
              todas as cirurgias realizadas em um só lugar. Siga quando fez cada
              cirurgia, busque por cirugião, hospital, pagamentos pendentes e
              muito mais.
            </Stack>
          </ListPaper>

          <ListPaper>
            <Stack>
              <Group>
                <ThemeIcon variant="light" radius="xl" size="xl">
                  <IconReceipt size={"2em"} />
                </ThemeIcon>
                <Title order={3}>Faturamento sem Esforço</Title>
              </Group>
              <Text>
                Diga adeus às horas gastas organizando papelada e deixe nosso
                aplicativo lidar com o processo de faturamento para você. Gere
                faturas para seus serviços de forma rápida e precisa.
              </Text>
            </Stack>
          </ListPaper>

          <ListPaper>
            <Stack>
              <Flex gap={"md"}>
                <ThemeIcon variant="light" radius="xl" size="xl">
                  <IconCoins size={"2em"} />
                </ThemeIcon>
                <Title order={3}>
                  Gere relatórios automaticamente para seu contador
                </Title>
              </Flex>
              <Text>
                Acabaram-se os dias de correria para listar todas as suas
                cirurgias na hora de declarar o imposto. O Instrumentar
                simplifica o processo, facilitando o envio de suas informações
                financeiras para seu contador. Passe menos tempo com papelada e
                mais tempo fazendo o que você ama - instrumentando!
              </Text>
            </Stack>
          </ListPaper>

          <ListPaper>
            <Stack>
              <Flex gap={"md"}>
                <ThemeIcon variant="light" radius="xl" size="xl">
                  <IconAlarm size={"2em"} />
                </ThemeIcon>
                <Title order={3}>
                  Lembretes de cirurgias com pagamento atrasado
                </Title>
              </Flex>
              <Text>
                O cirurgião esqueceu de te pagar? O plano esta demorando mais do
                que o normal pra te pagar? Não fique sem receber e se livre da
                carga mental de se lembrar quais cirurgias foram pagas. Receba
                Lembretes quando cirurgias não foram pagas quando deveriam!
              </Text>
            </Stack>
          </ListPaper>
        </Grid>

        <Stack>
          <Title order={2} mt={"xl"} size="lg" className={classes.description}>
            Mais funções no futuro próximo!
          </Title>
          <Text size="lg" className={classes.description}>
            Nós estamos sempre trabalhando duro para melhorar o aplicativo e
            facilitar ainda mais a sua vida!
          </Text>
          {/* <Text size="lg" className={classes.description}>
            Em breve o aplicativo vai contar com ainda mais funções, como Pix
            direto no aplicativo, calendário de disponibilidades e muito mais!
          </Text>
          <Text size={"lg"}>
            Por tempo limitado, o Instrumentar está disponível gratuitamente
            durante nossa fase de teste beta. Aproveite todos os recursos do
            aplicativo sem nenhum custo. Inscreva-se agora e experimente o
            futuro da instrumentação cirúrgica!
          </Text> */}
        </Stack>
        {/* <WaitList /> */}
      </Container>
      <LandingFooter />
    </Box>
  );
};
