export type Json =
  | string
  | number
  | boolean
  | null
  | { [key: string]: Json | undefined }
  | Json[];

export type Database = {
  public: {
    Tables: {
      profile: {
        Row: {
          coren: string | null;
          cpf: string | null;
          gender: number | null;
          location: string | null;
          name: string;
          rg: string | null;
          role: string | null;
          signature: ISignature | null;
          userid: string;
        };
        Insert: {
          coren?: string | null;
          cpf?: string | null;
          gender?: number | null;
          location?: string | null;
          name: string;
          rg?: string | null;
          role?: string | null;
          signature?: ISignature | null;
          userid?: string;
        };
        Update: {
          coren?: string | null;
          cpf?: string | null;
          gender?: number | null;
          location?: string | null;
          name?: string;
          rg?: string | null;
          role?: string | null;
          signature?: ISignature | null;
          userid?: string;
        };
        Relationships: [];
      };
      subscription: {
        Row: {
          created_at: string;
          end_date: string;
          userid: string;
        };
        Insert: {
          created_at?: string;
          end_date: string;
          userid: string;
        };
        Update: {
          created_at?: string;
          end_date?: string;
          userid?: string;
        };
        Relationships: [];
      };
      surgery: {
        Row: {
          amount: number | null;
          created_at: string;
          date: string | null;
          doctor: string | null;
          hospital: string | null;
          id: string;
          notes: string | null;
          owner: string;
          patient_id: string | null;
          patient_name: string | null;
          receipt_id: number | null;
          status: SurgeryStatus | null;
          title: string | null;
        };
        Insert: {
          amount?: number | null;
          created_at?: string;
          date?: string | null;
          doctor?: string | null;
          hospital?: string | null;
          id?: string;
          notes?: string | null;
          owner?: string;
          patient_id?: string | null;
          patient_name?: string | null;
          receipt_id?: number | null;
          status?: SurgeryStatus | null;
          title?: string | null;
        };
        Update: {
          amount?: number | null;
          created_at?: string;
          date?: string | null;
          doctor?: string | null;
          hospital?: string | null;
          id?: string;
          notes?: string | null;
          owner?: string;
          patient_id?: string | null;
          patient_name?: string | null;
          receipt_id?: number | null;
          status?: SurgeryStatus | null;
          title?: string | null;
        };
        Relationships: [];
      };
      surgery_old: {
        Row: {
          created_at: string;
          date: string | null;
          doctor: string | null;
          hospital: string | null;
          id: number;
          notes: string | null;
          owner: string;
          patient: string | null;
          receiptid: number | null;
          status: SurgeryStatus | null;
          surgery: string | null;
          value: number | null;
        };
        Insert: {
          created_at?: string;
          date?: string | null;
          doctor?: string | null;
          hospital?: string | null;
          id?: number;
          notes?: string | null;
          owner?: string;
          patient?: string | null;
          receiptid?: number | null;
          status?: SurgeryStatus | null;
          surgery?: string | null;
          value?: number | null;
        };
        Update: {
          created_at?: string;
          date?: string | null;
          doctor?: string | null;
          hospital?: string | null;
          id?: number;
          notes?: string | null;
          owner?: string;
          patient?: string | null;
          receiptid?: number | null;
          status?: SurgeryStatus | null;
          surgery?: string | null;
          value?: number | null;
        };
        Relationships: [];
      };
    };
    Views: {
      [_ in never]: never;
    };
    Functions: {
      check_cpf: {
        Args: {
          p_id: string;
          p_cpf: string;
        };
        Returns: boolean;
      };
      get_receipt: {
        Args: {
          p_id: string;
          p_cpf: string;
        };
        Returns: {
          surgery_data: Json;
          profile_data: Json;
        }[];
      };
      get_receipt_by_id_and_cpf: {
        Args: {
          p_id: string;
          p_cpf: string;
        };
        Returns: {
          amount: number | null;
          created_at: string;
          date: string | null;
          doctor: string | null;
          hospital: string | null;
          id: string;
          notes: string | null;
          owner: string;
          patient_id: string | null;
          patient_name: string | null;
          receipt_id: number | null;
          status: SurgeryStatus | null;
          title: string | null;
        };
      };
      get_surgeries_page: {
        Args: {
          p_user_id: string;
          p_page?: number;
          p_page_size?: number;
        };
        Returns: {
          surgeries: Json;
          total_count: number;
        }[];
      };
    };
    Enums: {
      [_ in never]: never;
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
};

type PublicSchema = Database[Extract<keyof Database, "public">];

export type Tables<
  PublicTableNameOrOptions extends
    | keyof (PublicSchema["Tables"] & PublicSchema["Views"])
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof (Database[PublicTableNameOrOptions["schema"]]["Tables"] &
        Database[PublicTableNameOrOptions["schema"]]["Views"])
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? (Database[PublicTableNameOrOptions["schema"]]["Tables"] &
      Database[PublicTableNameOrOptions["schema"]]["Views"])[TableName] extends {
      Row: infer R;
    }
    ? R
    : never
  : PublicTableNameOrOptions extends keyof (PublicSchema["Tables"] &
      PublicSchema["Views"])
  ? (PublicSchema["Tables"] &
      PublicSchema["Views"])[PublicTableNameOrOptions] extends {
      Row: infer R;
    }
    ? R
    : never
  : never;

export type TablesInsert<
  PublicTableNameOrOptions extends
    | keyof PublicSchema["Tables"]
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicTableNameOrOptions["schema"]]["Tables"]
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? Database[PublicTableNameOrOptions["schema"]]["Tables"][TableName] extends {
      Insert: infer I;
    }
    ? I
    : never
  : PublicTableNameOrOptions extends keyof PublicSchema["Tables"]
  ? PublicSchema["Tables"][PublicTableNameOrOptions] extends {
      Insert: infer I;
    }
    ? I
    : never
  : never;

export type TablesUpdate<
  PublicTableNameOrOptions extends
    | keyof PublicSchema["Tables"]
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicTableNameOrOptions["schema"]]["Tables"]
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? Database[PublicTableNameOrOptions["schema"]]["Tables"][TableName] extends {
      Update: infer U;
    }
    ? U
    : never
  : PublicTableNameOrOptions extends keyof PublicSchema["Tables"]
  ? PublicSchema["Tables"][PublicTableNameOrOptions] extends {
      Update: infer U;
    }
    ? U
    : never
  : never;

export type Enums<
  PublicEnumNameOrOptions extends
    | keyof PublicSchema["Enums"]
    | { schema: keyof Database },
  EnumName extends PublicEnumNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicEnumNameOrOptions["schema"]]["Enums"]
    : never = never
> = PublicEnumNameOrOptions extends { schema: keyof Database }
  ? Database[PublicEnumNameOrOptions["schema"]]["Enums"][EnumName]
  : PublicEnumNameOrOptions extends keyof PublicSchema["Enums"]
  ? PublicSchema["Enums"][PublicEnumNameOrOptions]
  : never;

export type CompositeTypes<
  PublicCompositeTypeNameOrOptions extends
    | keyof PublicSchema["CompositeTypes"]
    | { schema: keyof Database },
  CompositeTypeName extends PublicCompositeTypeNameOrOptions extends {
    schema: keyof Database;
  }
    ? keyof Database[PublicCompositeTypeNameOrOptions["schema"]]["CompositeTypes"]
    : never = never
> = PublicCompositeTypeNameOrOptions extends { schema: keyof Database }
  ? Database[PublicCompositeTypeNameOrOptions["schema"]]["CompositeTypes"][CompositeTypeName]
  : PublicCompositeTypeNameOrOptions extends keyof PublicSchema["CompositeTypes"]
  ? PublicSchema["CompositeTypes"][PublicCompositeTypeNameOrOptions]
  : never;

export type SurgeryStatus = 'scheduled' | 'pending' | 'paid' | 'cancelled';

export type ISurgery = Database['public']['Tables']['surgery']['Row'];

export type IProfile = Database['public']['Tables']['profile']['Row'];

export const FONT_NAMES = {
  Clareza: 'OoohBaby',
  Caráter: 'Licorice',
  Suave: 'Sacramento',
};

export type ValidFontNames = keyof typeof FONT_NAMES;

export type ISignature = {
  img?: string;
  text?: string;
  font?: ValidFontNames;
};