import {
  ActionIcon,
  Button,
  ButtonProps,
  CloseButton,
  CloseButtonProps,
  ActionIconProps,
  Menu,
  MenuItemProps,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { ForwardedRef, forwardRef } from "react";
import { Link, To, useNavigate } from "react-router-dom";

interface ILinkButtonProps extends ButtonProps {
  to: To;
}

export const LinkButton = forwardRef(
  (
    { children, ...props }: ILinkButtonProps,
    ref: ForwardedRef<HTMLAnchorElement> | null
  ) => (
    <Button ref={ref} {...props} component={Link}>
      {children}
    </Button>
  )
);

export const LinkCloseButton = ({
  ...props
}: CloseButtonProps & { to: To }) => (
  <CloseButton component={Link} {...props} />
);

export const LinkActionIcon = forwardRef(
  (
    { children, ...props }: ActionIconProps & { to: string },
    ref: ForwardedRef<HTMLAnchorElement>
  ) => (
    <ActionIcon ref={ref} component={Link} {...props}>
      {children}
    </ActionIcon>
  )
);

export const LinkMenuItem = ({
  children,
  ...props
}: MenuItemProps & { to: To }) => (
  <Menu.Item component={Link} to={props.to} leftSection={props.leftSection}>
    {children}
  </Menu.Item>
);

export const BackButton = ({ to }: { to?: string }) => {
  const navigate = useNavigate();
  const dest = to || "..";
  return (
    <ActionIcon
      variant="light"
      color="gray"
      size="xl"
      radius="xl"
      aria-label="Retornar"
    >
      <IconArrowLeft
        onClick={() => {
          navigate(dest);
        }}
      />
    </ActionIcon>
  );
};
