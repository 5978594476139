import classes from "./index.module.css";
import { MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Checkbox,
  Stack,
  ButtonProps,
  Alert,
  Flex,
  Divider,
  LoadingOverlay,
} from "@mantine/core";

import { GoogleIcon } from "../../components/icons";
import { Container } from "../../components/Container";
import { useSupabaseClient, useSession } from "@supabase/auth-helpers-react";

type ValidProviders = "google";

const GoogleButton = (
  props: ButtonProps & { onClick: (e: MouseEvent) => void }
) => (
  <Button
    leftSection={<GoogleIcon />}
    variant="default"
    color="gray"
    {...props}
  />
);

export function Register(props: PaperProps) {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const supabase = useSupabaseClient();
  const session = useSession();

  useEffect(() => {
    if (session) {
      navigate("/app");
    }
  }, [session, navigate]);

  const form = useForm({
    initialValues: {
      email: "",
      confirm: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Email inválido"),
      password: (val) =>
        val.length <= 6
          ? "Senha deve conter ao menos 6 letras ou dígitos"
          : null,
      confirm: (val, { password }) => {
        return val === password
          ? null
          : "Confirmação de senha e senha não correspondem";
      },
    },
  });

  const socialLogin = async (provider: ValidProviders) => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOAuth({
      provider,
    });
    setLoading(false);
    if (error) {
      setError(String(error.status));
    } else {
      return navigate("/app");
    }
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    const { data, error } = await supabase.auth.signUp({
      email: form.values.email,
      password: form.values.password,
    });
    setLoading(false);
    if (error) {
      setError(String(error.status));
    } else if (data.user) {
      return navigate("/verify-email");
    }
  };

  return (
    <Container>
      <Flex align="center" justify="center" h="100%">
        <Paper
          radius="md"
          p="xl"
          className={classes.paper}
          withBorder
          {...props}
        >
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Text size="xl">Crie sua conta</Text>

          <Group grow mb="md" mt="md">
            <GoogleButton onClick={() => socialLogin("google")} radius="xl">
              Conectar com Google
            </GoogleButton>
          </Group>

          <Divider
            label="Ou continue usando seu email"
            labelPosition="center"
            my="lg"
          />

          <form onSubmit={form.onSubmit(handleFormSubmit)}>
            <Stack>
              <TextInput
                required
                label="Email"
                type="email"
                name="email"
                placeholder="meuemail@exemplo.com"
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
                error={form.errors.email && "Email inválido"}
                radius="md"
              />

              <PasswordInput
                required
                label="Senha"
                placeholder="Sua senha"
                type="password"
                name="password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={
                  form.errors.password &&
                  "Senha deve conter ao menos 6 letras ou dígitos"
                }
                radius="md"
              />

              <PasswordInput
                required
                label="Confirme sua senha"
                placeholder="Sua senha"
                value={form.values.confirm}
                onChange={(event) =>
                  form.setFieldValue("confirm", event.currentTarget.value)
                }
                error={
                  form.errors.confirm &&
                  "Confirmação de senha e senha não correspondem"
                }
                radius="md"
              />

              <Checkbox
                label="Eu aceito os termos e condições de uso"
                checked={form.values.terms}
                onChange={(event) =>
                  form.setFieldValue("terms", event.currentTarget.checked)
                }
              />
              {error && <Alert color="red">{error}</Alert>}
            </Stack>

            <Group justify="space-between" mt="xl">
              <Button fullWidth type="submit">
                Cadastrar
              </Button>
            </Group>
          </form>
        </Paper>
      </Flex>
    </Container>
  );
}
